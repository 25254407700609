/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content h1 {
  font-size: 5rem;
  font-family: "Yellowtail", Times, serif, cursive;
  background-image: none;
  margin-bottom: 0px;
  margin-top: -2rem; }

.rich-content h2 {
  color: #000000;
  font-weight: 700;
  font-size: 2rem;
  background-image: url("/img/divider-black.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  padding-bottom: 3rem; }

.rich-content h2.center {
  background-position: center bottom;
  background-image: url("/img/divider-black.png"); }

.rich-content h2.center.center-light {
  background-image: url("/img/divider-black.png"); }

.rich-content h3 {
  background-image: url("/img/divider-black.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  padding-bottom: 2rem;
  margin-bottom: 0px;
  font-size: 2rem;
  color: #000000; }

.rich-content h4 {
  font-size: 2rem;
  color: white; }

.rich-content h5 {
  font-size: 2rem;
  font-family: "Yellowtail", Times, serif, cursive;
  color: #000000; }

.rich-content p {
  color: #ffffff; }

.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

/* rc-form */
.rc-form label {
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 1.5rem;
  margin-left: 1rem; }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h1 {
  color: #2c2c2c;
  font-weight: 700;
  font-size: 2rem;
  background-image: url("/img/divider-blue.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 3rem; }

.h2, h2 {
  color: white;
  font-weight: 700;
  font-size: 2rem;
  background-image: url("/img/divider-blue.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 3rem; }

.h3, h3 {
  color: #2f9cdc;
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 1.5rem; }

p {
  font-size: 1rem;
  line-height: 2rem; }

i {
  font-size: 1.5rem; }

/* align elements */
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

/* buttons */
.btn-primary {
  background-image: url("/img/btn-primary.png");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 2rem;
  background-color: transparent;
  width: 198px;
  border: none;
  cursor: pointer;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:active, .btn-primary.active, .btn-primary:focus {
    outline: none;
    background-color: transparent;
    border: none;
    color: #2c2c2c; }
  .btn-primary.disabled, .btn-primary[disabled],
  fieldset[disabled] .btn-primary {
    outline: none;
    background-color: transparent;
    border: none; }

.btn-secondary {
  background-image: url("/img/btn-secondary.png");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 2rem;
  background-color: transparent;
  width: 198px;
  border: none;
  margin-top: -2rem;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:focus, .btn-secondary.active.focus {
    outline: none; }
  .btn-secondary:hover, .btn-secondary.hover, .btn-secondary:focus {
    background-color: transparent;
    border: none; }
  .btn-secondary.disabled, .btn-secondary[disabled],
  fieldset[disabled] .btn-secondary {
    background-color: transparent; }

/* forms */
/* ... */
@font-face {
  font-family: 'fontello';
  src: url("/fonts/fontello.eot?13299736");
  src: url("/fonts/fontello.eot?13299736#iefix") format("embedded-opentype"), url("/fonts/fontello.woff2?13299736") format("woff2"), url("/fonts/fontello.woff?13299736") format("woff"), url("/fonts/fontello.ttf?13299736") format("truetype"), url("/fonts/fontello.svg?13299736#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?13299736#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-menu-1:before {
  content: '\e804'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-instagram:before {
  content: '\f32d'; }

/* '' */
/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Ubuntu", Helvetica, Arial, sans-serif;
  color: #ffffff; }

/*
 *	UTILITIES
 */
.carousel {
  height: 80vh;
  width: 100%;
  z-index: 1; }

.carousel-item {
  width: 100%;
  height: 80vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.shadow {
  width: 100%; }
  .shadow img {
    width: 100%;
    height: auto; }

.white {
  color: white; }

.darkblue {
  color: #1f74a5; }

.lightblue {
  color: #9EDAFF; }

.title {
  color: white;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 0; }

.subtitle {
  color: #2f9cdc;
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 1.5rem;
  margin-bottom: 0; }

.btn-back {
  color: #2c2c2c;
  text-decoration: none;
  margin-bottom: 2rem;
  display: block; }

/*
 *	GENERAL
 */
.nav-container {
  background-color: white;
  height: 50px;
  position: fixed;
  top: 30px;
  width: 100%;
  z-index: 100;
  left: 0;
  border-bottom: 1px solid #CCC;
  border-top: 1px solid #ccc; }

.main-navigation {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  z-index: 100;
  text-align: center; }

.nav, .navigation {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  display: block; }

.navbar-toggler {
  position: absolute;
  left: 0;
  top: 25px; }

.nav-item {
  display: inline-block;
  margin-left: 2rem;
  margin-right: 2srem; }

.nav-link {
  color: #2c2c2c;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: -0.5rem; }

.nav-item .active {
  color: #67c0f9; }

#home-link {
  display: none;
  position: fixed;
  left: 50%;
  margin-left: -25px;
  top: -30px; }

#logo img {
  width: 100px;
  height: 100px; }

.main-content {
  background-image: url("/img/bluebackground.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 200px;
  width: 100%; }

/*
* HOME
*/
.about--home {
  min-height: 200px;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-top: 10rem;
  padding-bottom: 10rem; }

/*
 * ABOUT
 */
.about, .sitemap {
  min-height: 200px;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-top: 15rem;
  padding-bottom: 10rem; }

/*
* SERVICES
*/
.services-wrapper {
  background-image: url("/img/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-top: -5rem; }

#service-logo {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  top: -1rem; }

.nav--services {
  margin: 0;
  padding: 0;
  width: 100%; }

.service-item {
  list-style-type: none;
  margin: 0;
  padding: 5px 0; }

.service-link-left, .service-link-right {
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 2.2rem;
  color: #2c2c2c; }

.service-link-left:after {
  content: '';
  height: 1px;
  width: 250px;
  background-color: #2f9cdc;
  position: absolute;
  right: -150px;
  margin-top: 3rem; }

.service-link-right:after {
  content: '';
  height: 1px;
  width: 250px;
  background-color: #2f9cdc;
  position: absolute;
  left: -150px;
  margin-top: 3rem; }

.service-link-left:hover, .service-link-right:hover {
  color: #2f9cdc;
  text-decoration: none; }

.service-header {
  width: 100%;
  text-align: center;
  position: relative; }
  .service-header img {
    width: 100%;
    height: auto; }
  .service-header h1 {
    color: white;
    font-family: "Yellowtail", Times, serif, cursive;
    bottom: 5rem;
    font-size: 2rem;
    background-image: url("/img/divider-white.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    padding-bottom: 3rem;
    position: absolute;
    bottom: 2rem;
    width: 100%;
    text-align: center;
    z-index: 10; }

.service-content {
  min-height: 200px;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-top: 15rem;
  padding-bottom: 10rem;
  margin-top: -10rem; }

.service-img {
  width: 100%;
  border: 10px solid #fff;
  margin-bottom: 30px; }

/*
* PORTFOLIO
*/
.portfolio-wrapper {
  position: relative;
  z-index: 10;
  background-image: url("/img/referencesbg.jpg");
  background-size: cover;
  background-position: bottom center;
  padding: 5rem 0; }

.portfolio-background {
  position: relative;
  z-index: 10; }

.portfolio-background-ie {
  display: none; }

.portfolio-content {
  /*position: absolute;
	top: 40%;
	left: 0;
	z-index: 100;
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	margin-top: -125px;*/ }

.portfolioslider .owl-stage {
  padding: 2rem 0; }

.portfolioslider .owl-item {
  border: 10px solid white; }

.portfolioslider .owl-item.active:nth-child(1n+4) {
  transform: rotate(-1deg); }

.portfolioslider .owl-item.active:nth-child(2n+4) {
  transform: rotate(4deg); }

.portfolioslider .owl-item.active:nth-child(3n+4) {
  transform: rotate(-3deg); }

.portfolioslider .owl-item.active:nth-child(4n+4) {
  transform: rotate(0deg); }

.portfolio-link {
  display: none;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(27, 146, 155, 0.7) 0%, rgba(47, 156, 220, 0.7) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b31b929b', endColorstr='#b32f9cdc',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  padding-top: 40%;
  cursor: pointer; }

.portfolioslider .owl-item:hover .portfolio-link {
  display: block; }

a.portfolioslider-arrow {
  display: block;
  position: absolute;
  top: 40%;
  width: 102px;
  height: 62px;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat; }
  a.portfolioslider-arrow:hover:after, a.portfolioslider-arrow:hover:before {
    opacity: 1; }
  a.portfolioslider-arrow.next {
    background-image: url("/img/arrow-right.png"); }
  a.portfolioslider-arrow.prev {
    background-image: url("/img/arrow-left.png"); }

.owl-prev, .owl-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -32px; }

.owl-prev {
  left: -105px; }

.owl-next {
  right: -5px; }

.filter {
  padding: 3rem 0; }

.filter-item {
  display: inline;
  color: white;
  text-transform: uppercase;
  line-height: 2rem;
  font-weight: 700;
  margin-left: 1rem;
  margin-right: 1rem; }

.references-wrapper {
  position: relative;
  z-index: 10;
  margin-top: 0rem; }

.references-content {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 100;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%; }

/*
* REFERENTIE DETAIL
*/
.reference-header {
  margin-top: -1rem; }

.reference-img:nth-child(1) img {
  border: 10px solid white;
  transform: rotate(2deg); }

.reference-img:nth-child(2n+0) img {
  border: 10px solid white;
  transform: rotate(-3deg); }

.reference-img:nth-child(2n+1) img {
  border: 10px solid white;
  transform: rotate(1deg); }

.reference-img:nth-child(3n+0) img {
  border: 10px solid white;
  transform: rotate(4deg); }

/*
* CONTACT
*/
.contact-header {
  width: 100%;
  text-align: center;
  position: relative; }
  .contact-header img {
    width: 100%;
    height: auto; }

.contact-text, .contact-text a {
  line-height: 1.6rem;
  color: #FFFFFF;
  font-weight: 700; }

/* 
* NEWS
*/
.news-wrapper {
  z-index: 10;
  background-image: url("/img/newsbg.jpg");
  background-size: cover;
  background-position: bottom center; }

.news-background {
  position: relative;
  z-index: 10; }

.news-background-ie {
  display: none; }

.news-content {
  padding: 5rem 0 3rem 0;
  /*position: absolute;
	top: 30%;
	margin-top: -125px;
	left: 0;
	z-index: 100;
	width: 100%;*/ }

.facebook-item .fb-text {
  color: #fff;
  text-align: left;
  font-size: 0.8rem; }

.facebook-item .right {
  text-align: right !important; }

.facebook-item img {
  border: 5px solid #fff;
  max-height: 100px; }

.facebook-item .fb-link {
  color: #2f9cdc;
  font-family: "Yellowtail", Times, serif, cursive;
  font-size: 1.2rem; }

/*
* FOOTER
*/
footer {
  background-color: black;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 0rem;
  color: white; }
  footer .text {
    margin-top: 2rem;
    line-height: 1.3rem; }
  footer a {
    color: white;
    text-decoration: none; }
  footer .partner--img {
    margin-right: 2rem; }
  footer .partner--link {
    font-size: 1.2rem; }

.footer__bottom {
  background-color: #151515;
  padding: 1rem 0;
  font-size: 0.8rem;
  color: #999;
  margin-top: 3rem; }
  .footer__bottom a {
    font-size: 0.8rem;
    color: #999;
    margin-right: 1rem; }
  .footer__bottom .text-right a {
    margin-right: 0; }

/*
* COOKIES
*/
.cookie-pop-up p {
  color: #454545; }

/*
* SITEMAP
*/
.sitemap a {
  color: #fff;
  border-bottom: 1px dotted #fff; }

.sitemap h3 {
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff; }

/*
* FANCYBOX
*/
.fancybox-overlay-fixed {
  background-color: rgba(0, 0, 0, 0.9) !important; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_items.png") !important; }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_items@2x.png") !important;
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
 *	IMPORTS 
 */
/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel .owl-refresh .owl-item {
  display: none; }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d; }

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; }

.owl-carousel .owl-grab {
  cursor: move;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img {
  transform-style: preserve-3d; }

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: scale 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1; }

/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 543px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
.ie10 .portfolio-background-ie, .ie10 .news-background-ie, .ie11 .portfolio-background-ie, .ie11 .news-background-ie {
  display: block; }

.ie10 .portfolio-background, .ie10 .news-background, .ie11 .portfolio-background, .ie11 .news-background {
  display: none; }

/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 1199px) {
  /*
* SERVICES
*/
  .service-item {
    list-style-type: none;
    margin: 0;
    padding: 5px 0; }
  .service-link-left, .service-link-right {
    font-size: 1.5rem; }
  .service-link-left:after {
    content: '';
    height: 1px;
    width: 250px;
    background-color: #2f9cdc;
    position: absolute;
    right: -150px;
    margin-top: 2rem; }
  .service-link-right:after {
    content: '';
    height: 1px;
    width: 250px;
    background-color: #2f9cdc;
    position: absolute;
    left: -150px;
    margin-top: 2rem; }
  footer td {
    width: 100%;
    display: block; }
  .footer__bottom {
    text-align: center; }
    .footer__bottom .text-right {
      text-align: center !important;
      padding-top: 2rem; } }

@media (max-width: 991px) {
  #home-link {
    display: block; }
  .nav .home-link {
    display: none; }
  .main-navigation {
    position: fixed;
    top: 10px; }
  .nav, .navigation {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    display: block; }
  .nav {
    position: relative;
    top: 70px; }
  .nav-item {
    display: block;
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    background-color: rgba(255, 255, 255, 0.9); }
  .nav-link {
    color: #2c2c2c;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-top: 0; }
  .dropdown-menu {
    width: 100%;
    text-align: center; }
  /*
* SERVICES
*/
  .services .text-left, .services .text-right {
    text-align: center !important;
    margin-bottom: 2rem; }
  .service-link-left:after {
    width: 100px;
    left: 50%;
    margin-left: -50px;
    margin-top: 2rem; }
  .service-link-right:after {
    width: 100px;
    left: 50%;
    margin-left: -50px;
    margin-top: 2rem; }
  /* 
* PORTFOLIO
*/
  .portfolio-content {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%; }
  .portfolio-link {
    font-size: 1rem;
    top: 0;
    padding-top: 40%;
    cursor: pointer; }
  a.portfolioslider-arrow {
    display: block;
    position: absolute;
    top: 40%;
    width: 51px;
    height: 31px;
    opacity: 0.5;
    background-position: center;
    background-repeat: no-repeat; }
    a.portfolioslider-arrow:hover:after, a.portfolioslider-arrow:hover:before {
      opacity: 1; }
    a.portfolioslider-arrow.next {
      background-image: url("/img/arrow-right.png");
      background-size: 51px 31px; }
    a.portfolioslider-arrow.prev {
      background-image: url("/img/arrow-left.png");
      background-size: 51px 31px; }
  .owl-prev, .owl-next {
    margin-top: -16px; }
  .owl-prev {
    left: -55px; }
  .owl-next {
    right: -5px; }
  /*
* NEWS
*/
  .facebook-item img {
    display: none; }
  .facebook-item .fb-link {
    font-size: 1rem; }
  .facebook-item .fb-date {
    display: none; } }

@media (max-width: 767px) {
  .text-left, .text-right {
    text-align: center; }
  h1, h2, h3 {
    background-position: center bottom; }
  .rich-content h1, .rich-content h2, .rich-content h3 {
    background-position: center bottom; }
  footer {
    text-align: center; }
    footer table, footer tr, footer td {
      width: 100%;
      text-align: center; }
    footer .partner--img {
      margin-right: 0rem; }
  .news-wrapper {
    display: none; } }

@media (max-width: 543px) {
  .text-left, .text-right {
    text-align: center; }
  h1, h2, h3 {
    background-position: center bottom; }
  .rich-content h1, .rich-content h2, .rich-content h3 {
    background-position: center bottom; }
  /*
	* PORTFOLIO
	*/
  .portfolio-content {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%; }
  .references-content {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%; } }

/*# sourceMappingURL=app.css.map */
